.experiencia-container {
    padding: 30px;
    color: white;
    text-align: center;
    width: 70%; /* Contenedor más estrecho */
    margin: 0 auto; /* Centrado */
    border-radius: 10px;
  }
  
  /* Título */
  .title {
    font-size: 2.5rem;
    color: #17b978;
    margin-bottom: 20px;
  }
  
  .text {
    color: white;
  }
  
  /* Contenedor de la sección con flexbox */
  .section-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  /* Contenedor de experiencia y estudios */
  .experience-container,
  .studies-container {
    width: 40%; /* Contenedores más pequeños */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Sombra verde */
    border: 2px solid #17b978; /* Borde verde */
    box-shadow: 0 4px 8px #17b978;
    text-align: left; /* Alineación del texto a la izquierda */
  }
  
  /* Títulos de cada contenedor */
  h3 {
    color: #17b978;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  /* Títulos secundarios */
  h4 {
    font-size: 1.2rem;
    color: #17b978;
    margin-bottom: 10px;
  }
  
  /* Estilo de los textos */
  p {
    font-size: 1rem;
    color: #ccc;
    margin-top: 10px;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .section-container {
      flex-direction: column;
      align-items: center;
    }
  
    .experience-container, .studies-container {
      width: 90%; /* Ancho del 90% en pantallas pequeñas */
    }
  }
  
  @media (max-width: 480px) {

      /* Título */
  .title {
    font-size: 2rem;
    color: #17b978;
    margin-bottom: 50px;
  }
  
  .text {
    color: white;
  }
    .experiencia-container {
        padding: 20px;
        color: white;
        text-align: center;
        width: 80%; /* Contenedor más estrecho */
        margin: 0 auto; /* Centrado */
        border-radius: 10px;
      }
      
    .section-container {
      flex-direction: column;
      align-items: center;
    }
  
    .experience-container, .studies-container {
      width: 80%; /* Ancho del 100% en pantallas más pequeñas */
        margin-right: 100px;
        padding: 30px;
    }
  
    .footer {
      font-size: 0.9rem; /* Reducir tamaño de fuente */
    }
  }
