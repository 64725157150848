/* Contenedor de la barra de navegación */
.navbar {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
  
}

/* Estilo para los botones de navegación */
.nav-button {
  width: 50px;
  height: 50px;
  background-color:transparent ;
  color: #17b978;
  font-size: 1.7rem;
  box-shadow: 3px 3px 19px #17b978;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: auto;
  outline: none;
  padding: 0;
  font-weight: bold;
}

.nav-button:hover {
  opacity: 1;
  background-color: #17b978;
  color: white;
}

.nav-button.left {
  margin-left: 100px;
}

.nav-button.right {
  margin-right: 100px;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .navbar {
    top: 30px; /* Colocamos la barra en la parte superior de la pantalla */
    transform: translateY(0); /* Eliminar el transform para centrado */
  }

  .nav-button {
    font-size: 1.2rem; /* Reducir tamaño de los botones */
    width: 35px; /* Ajustar el tamaño del botón */
    height: 35px; /* Ajustar el tamaño del botón */
  }

  .nav-button.left,
  .nav-button.right {
    margin-left: 10px; /* Reducir espacio en los laterales */
    margin-right: 10px; /* Reducir espacio en los laterales */
  }
}
