/* Estilo para el contenedor de contacto */
.contacto {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrado horizontal */
  justify-content: center;
  padding: 20px 20px;
  min-height: 100vh; /* Asegura que ocupe todo el alto de la pantalla */
  color: white;
  width: 100%; /* Asegura que ocupe el 100% del ancho */
  overflow-y: auto; /* Habilita el scroll en el eje vertical */
  box-sizing: border-box; /* Asegura que los paddings no afecten el tamaño */
}

/* Título de la sección de contacto */
.contacto-title {
  font-size: 3rem;
  color: #17b978; /* Verde */
  margin-bottom: 50px;
  text-align: center;
}

/* Contenedor de los elementos dentro de contacto */
.contacto-container {
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  gap: 50px;
  width: 70%;
  max-width: 1400px;
  flex-wrap: wrap; /* Permite el ajuste del contenido en pantallas pequeñas */
  margin: 0 auto; /* Centra el contenedor */
  padding-bottom: 20px; /* Para asegurar que no haya corte de contenido */
}

/* Información de contacto */
.contacto-info {
  flex: 1;
  background-color: #2c2f33;
  padding: 30px;
  box-shadow: 0 4px 8px #17b978;
  border: 2px solid #17b978;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
  max-width: 800px;
  margin-bottom: 20px; /* Añadimos espacio debajo */
}

/* Títulos de la información de contacto */
.contacto-info h2 {
  font-size: 1.5rem;
  color: #17b978;
  margin-bottom: 15px;
}

/* Estilo para los íconos de contacto */
.contacto-info .info-item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.contacto-info .info-item p {
  margin: 0;
  color: white;
  font-size: 0.9rem;
}

/* Estilo del formulario de contacto */
.contacto-form {
  flex: 1;
  background-color: #2c2f33;
  padding: 30px;
  box-shadow: 0 4px 8px #17b978;
  border: 2px solid #17b978;
  max-width: 700px;
  margin-bottom: 20px; /* Añadimos espacio debajo */
}

/* Grupos del formulario */
.contacto-form .form-group {
  margin-bottom: 15px;
}

.contacto-form label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: white;
}

.contacto-form input,
.contacto-form textarea {
  width: 94%; /* Aumento el ancho de los cuadros */
  padding: 12px;
  border: 2px solid #17b978;
  border-radius: 5px;
  background-color: #1f2225;
  color: white;
  font-size: 1rem;
  outline: none;
}

.contacto-form textarea {
  resize: none;
}

/* Estilo para el botón de enviar */
.contacto-form button {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #17b978;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contacto-form button:hover {
  background-color: #0a1e30;
}

/* Estilo del texto de contacto */
.contacto-text {
  color: white;
}

/* Estilo de la imagen del mapa */
.map-image {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  border-radius: 10px;
  display: block; /* Asegura que la imagen ocupe el 100% del contenedor */
  margin: 0 auto; /* Centra la imagen */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .contacto-container {
    flex-direction: column;
    align-items: center; /* Centrado en columna */
  }
  .contacto-info,
  .contacto-form {
    width: 100%;
    max-width: 800px;
  }
  .contacto-title {
    font-size: 2rem;
  }
  .contacto-form input,
  .contacto-form textarea {
    width: 100%;
    padding: 15px;
  }
}

/* Ajustes para pantallas móviles */
@media (max-width: 480px) {
  .contacto {
    padding-top: 130%;
  }
  .contacto-container {
    padding-right: 60px;
    flex-direction: column;
    align-items: center; /* Asegura que los elementos estén centrados en móviles */
  }
  .contacto-info,
  .contacto-form {
    width: 100%;
    max-width: 350px; /* Ajuste de tamaño para móviles */
  }
  .contacto-title {
    font-size: 2rem;
  }
  .contacto-form input,
  .contacto-form textarea {
    width: 85%;
    padding: 15px;
  }
}
