/* Estilos generales del portafolio */
.portafolio {
  padding: 20px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.portafolio-title {
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #ffffff;
}

span {
  color: #17b978;
}

/* Botones del portafolio */
.portafolio-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.portafolio-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  border: 2px solid #17b978;
  background: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 6px #17b978;

  transition: background 0.3s ease, color 0.3s ease;
}

.portafolio-button.active,
.portafolio-button:hover {
  background: #17b978;
  color: white;
}

/* Contenido del portafolio */
.portafolio-content {
  display: flex;
  flex-direction: row; /* Coloca los elementos lado a lado */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 60%; /* Ocupa todo el ancho */
  height: auto;
  overflow-y: auto; /* Permite el scroll */
}

/* Ajuste del texto */
.portafolio-text {
  width: 40%; /* Ajustamos el texto para que ocupe el 60% del contenedor */
  text-align: right; /* Justifica el texto a la derecha */
  overflow: visible;

}

.portafolio-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.portafolio-text hr {
  width: 100%;
  height: 1px;
  border: 1px solid #17b978;
  margin: 10px 0;
}

.portafolio-text p {
  font-size: 1rem;
  line-height: 1.8;
  overflow: visible;
}

/* Ajuste del video */
.project-video {
  width: 75%; /* Ajusta el video para que ocupe el 35% del ancho */
  height: 350px;
  margin-top: 10px; /* Añade espacio entre el texto y el video */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  border: 1px solid #17b978;
  box-shadow: 0 6px 10px #17b978;

}

.span{
  color: #17b978;
}

/* Responsividad para móviles */
@media (max-width: 480px) {
.portafolio-content {
  flex-direction: column; /* Coloca los elementos verticalmente */
  gap: 20px; /* Añade espacio entre el texto y el video */
  width: 100%;
  height: auto;
  overflow-y: auto; /* Asegura el scroll vertical */
}

.portafolio-text {
  width: 90%;
  margin-top: 290px;
  text-align: center;
  max-height: none;
  overflow: visible;
}
.portafolio-text hr {
  width: 100%;
  height: 1px;
  border: 1px solid #17b978;
  margin: 10px 0;
}

.project-video {
  
  height: 250px; /* Limita la altura del video en móviles */
  margin-top: 10px; /* Añade espacio entre el texto y el video */
}

.portafolio-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.portafolio-button {
  padding: 10px 10px;
  font-size: 0.8rem;
}

.portafolio-title {
  font-size: 2rem;
  margin-bottom: 20px;
}
}
