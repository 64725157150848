/* Contenedor principal */
.home {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    overflow: hidden;
    padding: 10px; /* Ajuste para evitar que el contenido se corte */
  }
  
  /* Caja de contenido */
  .caja {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra todo el contenido dentro de la caja */
  }
  
  /* Imagen de perfil */
  .profile-image2 {
    width: 170px;
    height: auto;
    margin-bottom: 20px;
    border: 4px solid #17b978;
    box-shadow: 0 6px 10px #17b978;
  }
  
  /* Iconos de coding a la izquierda y derecha */
  .coding-icon-left, .coding-icon-right {
    width: 40px;
    height: 40px;
    margin-left: -10px;
    border-radius: 50%;
    padding: 10px;
  }
  
  /* Texto "WEB DEVELOPER" */
  .web-developer {
    color: #17b978;
    font-weight: bold;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 10px;
  }
  
  /* Títulos */
  .home h1 {
    font-size: 2.7rem;
    font-weight: bold;
  }
  
  .home h1 span {
    color: #17b978;
  }
  
  .home h2 {
    font-size: 1.7rem;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el contenido de h2 */
  }
  
  /* Línea decorativa */
  hr {
    width: 150px;
    height: 3px;
    background-color: #17b978;
    border: none;
    margin: 20px 0;
  }
  
  /* Texto descriptivo */
  .home p {
    font-size: 1.1rem;
    margin: 20px 0;
    line-height: 30px;
  }
  
  /* Contenedor de íconos sociales */
  .home .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: 20px 0;
  }
  
  /* Estilo de los íconos */
  .home .social-icons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #17b978;
    border-radius: 50%;
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    z-index: 10;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  /* Efecto hover */
  .home .social-icons a:hover {
    background-color: white;
    color: #17b978;
    transform: scale(1.1);
  }
  
  /* Adaptaciones para pantallas pequeñas */
@media (max-width: 768px) {
    .caja {
      width: 90%;
    }
  
    .home h1 {
      font-size: 2.2rem;
    }
  
    .home h2 {
      font-size: 1.4rem;
    }
  
    .home p {
      font-size: 0.9rem;
    }
  
    .home .social-icons a {
      width: 35px;
      height: 35px;
      font-size: 1rem;
    }
  
    hr {
      width: 100px;
      height: 2px;
    }
  
    .profile-image2 {
      width: 120px;
    }
  }
  
  @media (max-width: 480px) {
    .home h1 {
      font-size: 1.8rem;
    }
  
    .home h2 {
      font-size: 1.3rem;
    }

    .home h1 span {
      color: #17b978;
    }

    .web-developer {
      color: #17b978;
      font-weight: bold;
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding-left: 5px;
    }
  
    .profile-image2 {
      width: 100px;
    }
  
    .home p {
      font-size: 0.8rem;
      line-height: 1.4rem;
    }
  
    .home .social-icons a {
      width: 40px;
      height: 40px;
      font-size: 1.1rem;
    }
  
    hr {
      width: 80px;
      height: 2px;
    }
    .home .social-icons a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: #17b978;
      border-radius: 50%;
      color: white;
      font-size: 1.2rem;
      text-decoration: none;
      z-index: 10;
      transition: transform 0.3s ease, background-color 0.3s ease;
    }
      /* Iconos de coding a la izquierda y derecha */
  .coding-icon-left, .coding-icon-right {
    width: 30px;
    height: 30px;
    margin-left: -5px;
    border-radius: 50%;
    padding: 10px;
  }
  }