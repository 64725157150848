/* Fondo global */
.app-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
      to bottom right,
      #17b97827 18%, /* Verde suave */
      rgba(10, 30, 48, 0) 55% /* Transición hacia transparente */
    ),
    #0a1e30; /* Azul oscuro sólido */
    overflow-y: auto; /* Permitir el scroll vertical */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Contenedor principal */
.sections-container {
  display: flex;
  flex-direction: column; /* Usar flexbox para un scroll vertical normal */
  width: 100%;
  height: auto;
  overflow-y: auto; /* Permitir scroll en la página */
}

/* Contenedor de las secciones */
.section {
  width: 100%;
  min-height: 100vh; /* Asegura que las secciones ocupen toda la altura de la ventana */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


/* Botones laterales */
.nav-button {
  position: fixed;
  top: 50%; /* Posiciona los botones en el centro de la pantalla */
  z-index: 1000;
  font-size: 2rem;
  background-color: transparent;
  color: white;
  border: 2px solid #17b978; /* Borde verde */
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  transform: translateY(-50%);
}

.nav-button:hover {
  background-color: #17b978; /* Fondo verde al pasar el ratón */
}

.nav-button.left {
  left: 10px; /* Botón de la izquierda */
}

.nav-button.right {
  right: 10px; /* Botón de la derecha */
}


/* Responsiveness: Smaller buttons on mobile */
@media (max-width: 768px) {
  .btn-class-name {
    width: 50px;
    height: 40px;
  }
}




.nav-button:hover {
  background-color: #17b978;
}

.nav-button.left {
  left: 10px; /* Botón de la izquierda */
}

.nav-button.right {
  right: 10px; /* Botón de la derecha */
}

/* Iconos flotantes */
.icon {
  position: fixed;
  font-size: 35px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.icon.react {
  animation: moveReact 20s linear infinite;
}

.icon.html {
  animation: moveHtml 22s linear infinite;
}

.icon.css {
  animation: moveCss 18s linear infinite;
}

.icon.js {
  animation: moveJs 25s linear infinite;
}

.icon.java {
  animation: moveJava 26s linear infinite; /* Animación para Java */
}

.icon.angular {
  animation: moveAngular 28s linear infinite; /* Animación para Angular */
}

@keyframes moveReact {
  0% { top: 10%; left: 10%; }
  50% { top: 50%; left: 50%; }
  100% { top: 10%; left: 10%; }
}

@keyframes moveHtml {
  0% { top: 80%; left: 10%; }
  50% { top: 60%; left: 70%; }
  100% { top: 80%; left: 10%; }
}

@keyframes moveCss {
  0% { top: 20%; left: 90%; }
  50% { top: 40%; left: 60%; }
  100% { top: 20%; left: 90%; }
}

@keyframes moveJs {
  0% { top: 50%; left: 20%; }
  50% { top: 75%; left: 75%; }
  100% { top: 50%; left: 20%; }
}

@keyframes moveJava {
  0% { top: 30%; left: 20%; }
  50% { top: 60%; left: 80%; }
  100% { top: 30%; left: 20%; }
}

@keyframes moveAngular {
  0% { top: 70%; left: 50%; }
  50% { top: 40%; left: 20%; }
  100% { top: 70%; left: 50%; }
}

/* Imagen del PC */
.image-container {
  position: fixed;
  bottom: -40px; /* Ligeramente fuera del borde inferior */
  right: -40px; /* Ligeramente fuera del borde derecho */
  width: 500px; /* Tamaño ajustado */
  height: auto;
  z-index: 1; /* Delante de otros elementos */
}

.pc-image {
  width: 100%;
  height: auto;
  opacity: 0.8; /* Más transparente */
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.5)); /* Sombra */
  border-radius: 15px; /* Bordes redondeados */
}



/* Responsivo: Ajustes para móviles */
@media (max-width: 768px) {
  .image-container {
    display: none;
  }
  /* Fondo global */
.app-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
      to bottom right,
      #17b97827 10%, /* Verde suave */
      rgba(10, 30, 48, 0) 25% /* Transición hacia transparente */
    ),
    #0a1e30; /* Azul oscuro sólido */
  overflow: auto; /* Evitar el scroll */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .btn-class-name {
    width: 40px; /* Reducir el tamaño del botón */
    height: 35px; /* Reducir el tamaño del botón */
  }

  .btn-class-name .front {
    font-size: 1rem; /* Reducir el tamaño del texto en el botón */
  }

  /* Posicionar los botones en la parte superior */
  .btn-class-name.left,
  .btn-class-name.right {
    top: 40px; /* Mantener los botones arriba */
  }

}
}
