/* Estilo para el contenedor de habilidades */
.skills {
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto; /* Permitir el scroll si el contenido excede la altura de la pantalla */
  }
  
  /* Ajustes de la imagen del PC */
  .image-container {
    position: fixed;
    bottom: -40px; /* Ligeramente fuera del borde inferior */
    right: -40px; /* Ligeramente fuera del borde derecho */
    width: 500px; /* Tamaño ajustado */
    height: auto;
    z-index: 1; /* Estará sobre otros elementos */
  }
  
  /* Imagen del PC */
  .pc-image {
    width: 100%;
    height: auto;
    opacity: 0.8;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.5));
    border-radius: 15px;
  }
  
  /* Ocultar la imagen en móviles */
  @media (max-width: 768px) {
    .image-container {
      display: none; /* Oculta la imagen en pantallas móviles */
    }
    .skills {
      height: auto; /* Permite que el contenido crezca en la altura */
    }
  }
  
  /* Estilos del título */
  .skills-title {
    font-size: 2.5rem;
    color: #17b978;
    margin-bottom: 40px;
  }
  
  /* Estilos del contenedor de habilidades */
  .skills-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
  }
  
  /* Tarjetas de habilidades */
  .skill-card {
    background-color: transparent;
    border: 2px solid #17b978;
    box-shadow: 0 3px 8px #17b978;

    border-radius: 8px;
    padding: 30px;
    width: 70%; /* Esto asegura que se ajuste al 100% dentro del contenedor */
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .skill-card:hover {
    background-color: #17b978;
    cursor: pointer;
  }
  
  .skill-card h3 {
    font-size: 1.5rem;
    color: white;
  }
  
  .skill-card p {
    font-size: 1rem;
    color: white;
  }
  
  .skill-icon {
    font-size: 3rem;
    color: white;
    margin-bottom: 15px;
  }
  
  .skill-card:hover .skill-icon {
    color: #ffffff;
  }
  span{
    color: white;
  }
  
  /* Responsividad para móviles */
  @media (max-width: 768px) {
    .skills {
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding-top: 250%;
      }
    .skills-container {
    
      grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas más pequeñas */
    }
  }
  
  @media (max-width: 480px) {
    .skills-container {
      grid-template-columns: 1fr; /* 1 columna en pantallas aún más pequeñas */
    }
    .skills-title {
      font-size: 2rem; /* Reducir el tamaño del título en móviles */
    }
    .skill-card {
      padding: 20px; /* Reducir el padding de las tarjetas en móviles */
    }
  }
  